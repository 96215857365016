// Disabled scroll right
body.homepage { overflow-x: hidden; }


// Homepage Features - Layout
.homeFeaturesWrapper {
  display: flex;
  flex-wrap: wrap;
  .homeFeatures.homeFeaturesTop {
    order: -1;
  }
}


// Desktop Layout - Overall
@media (min-width: #{$nav-breakpoint + 1px}) {
  // Navigation - Layout
  .menuMain { max-width: calc(#{$header-content-max-width} - #{$site-bleed * 2} + 25px); }
  .menuMain ul.topLevel { margin-right: 0; }

  // Header content - Layout
  .pageHeader .headerContent,
  .pageHeader.sticky .headerContent {
    margin-top: $spacer * 2;
    margin-bottom: 0;
  }

  .pageHeader.sticky .mainLogo {
    width: $logo-width;
    height: $logo-height;
  }

  // Header MainCalltoAction - Layout
  .pageHeader .headerContent > *:not(.mainLogo) {
    margin-top: 0;
    margin-bottom: 0;
  }
}


// Navigation - Reponsive font size
@media (min-width: #{$nav-breakpoint + 1px}) and (max-width: 1140px) {
  .menuMain .topLevel li.level1 > a {
    font-size: 0.916rem;
  } 
}


// Header Ghost button - Format
.mainCallToAction .cta-button.ghost {
  background-color: $white;
  color: $btn-background-colour;
  box-shadow: inset 0px 0px 0px 3px $btn-background-colour;
  &:hover {
    background-color: darken($btn-background-colour, 5%);
    color: text-contrast($btn-background-colour);
    box-shadow: inset 0px 0px 0px 3px darken($btn-background-colour, 5%);
  }
}


// Carousel details - Elements
.carouselSlideDetail #totaliserActions {
  display: none;
}

// Carousel - Desktop
@media (min-width: #{$carousel-breakpoint + 1px}) {
  // Carousel - Heading
  .carouselSlideHeading a {
    @include highlighter (
      $border-radius: $border-radius
    );
  }

  // Carousel summary
  .carouselSlideSummary {
    @include highlighter (
      $background-colour: $white,
      $border-radius: $border-radius
    );
  }
}


// Home Intro - Background
.homeIntroWrapper:before {
  background-size: auto 50%;
  transform: rotate(17deg);
}


// Home Headlines Intro - Format
.homeHeadlinesIntro p { margin-bottom: 0; }
.homeHeadlinesIntro p span {
  font-family: $headings-font-family;
  font-size: $home-intro-font-size;
  font-weight: $font-weight-bold;
}

// Home Features - Background
.homeHeadlinesIntro,
.homeFeatures:not(.homeFeaturesTop) {
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: $grey-lightest;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}


// Card layout - Overall
.homeFeatures .homeFeature,
.feedItem,
.listedPost {
  overflow: visible !important;

  // Element position
  [class*="DetailsWrapper"], .listedPostText, .listedProductText {
    z-index: 1;
    // Text spacing
    > h2, > h3, > p {
      margin-top: -20px;
      border-radius: $border-radius;
      transition: $transition-duration $transition-curve;
    }
    > h2, > h3 { padding: 10px 20px; }
    > p, .productPrice { padding: 20px; }
  }
  // Hover state - Image
  &:hover {
    [class*="image-link"] {
      border-radius: 2px;
      .banner { transform: scale(1.1) rotate(2deg) !important; }
    }
    h2, h3 { transition: $transition-duration $transition-curve; }
  }
}


// Home feeds - Layout
@media (min-width: map-get($breakpoints, md) + 1px) {
  .homeFeedBox1 { flex: 2; }
}


// Feeds title - Format
.homeFeed:nth-of-type(odd) .feedsTitle {
  @include heading-underline(
    $colour: $_purple-dark,
    $width: 100%,
    $height: 3px,
    $margin-top: 0.25em
  );
}
.homeFeed:nth-of-type(even) .feedsTitle {
  @include heading-underline(
    $colour: $_purple,
    $width: 100%,
    $height: 3px,
    $margin-top: 0.25em
  );
}


// Footer Social - Layout
.footerBox[class*='ocial'] {
  padding: #{$spacer * 2} $site-bleed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $grey-lightest;
  .socialIcons a:before { color: $footer-background-colour; }
  .socialIcons a:hover:before { color: darken($footer-background-colour, 10%); }
}

// Footer overall - Layout 
.pageFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $footer-padding-y $site-bleed;
}

// Footer links
@media (min-width: map-get($breakpoints, md) + 1px) {
  .footerBox  ul {
    display: flex;
    padding-left: 0;
    li { margin: 0 10px; }
    a { font-weight: $font-weight-bold; }
  }
}

// Footer - Icons
.footerBox[class*='indus'] ul a {
  &:before {
    display: inline-block;
    font-family: $icon-font-family;
    margin-right: 5px;
    font-size: 1em;
    color: $footer-link-colour;
  }
  &.location:before { content: '\f041'; }
  &.email:before { content: '\f0e0'; } // content: '\f003';
  &.phone:before { content: '\f095'; }
}


// Quick Giving Panel
.quickGivingPanel {
  .formQuestion.donationAmount { box-shadow: inset 0px 0px 0px 2px $donate-colour; }
  // .formQuestion.donationAmount.donationSelected { box-shadow: inset 0px 0px 0px 2px $donate-colour; }
  .donateBtn { border-radius: $border-radius; }
  // .formQuestion.donationAmount:hover .donationAmountFigure { color: $donate-colour !important; }
}

// Newsletter button - Format
.footerBox[class*="ewsletter"] button {
  border-radius: $border-radius;
}


// Subsite
.subsiteTitle {
 @include highlighter (
    $border-radius: $border-radius
  ); 
}

.subsite .contentContainer {
  .headerWrapperSubsite { order: 1; }
  .headerTextSubsite { display: none; }
  .menuSub { order: 3; }
  .subsiteBody { order: 4; }
  .subsiteFeedsWrapper { order: 5; }
}

.subsiteFeedsWrapper {
  padding-top: $spacer;
  padding-bottom: $spacer;
}
.subsiteFeeds {
  .subsiteFeed {
    margin-right: 0;
    flex: unset;
    padding-top: $spacer * 2;
    padding-bottom: $spacer;
    .feedsTitle { text-align: center; }
  }
}

.subsiteFeedBox2.subsiteFeedsnippets {
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: $grey-lightest;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}


.subsiteFeedsnippets .feedItem {
  padding: 20px;
  text-align: center;
  p { padding: 0; }
}


// Map

#clickableMap {
	max-width: 580px;
}

#Ireland {
	polygon {
		fill: #e6e6e6;
	}
}

#northern-ireland {
	path {
		fill: #6DB646;
	}
	&:hover {
		path {
			fill: darken(#6DB646, 10);
		}
	}
}

#wales {
	path {
		fill: #FBBA00;
	}
	&:hover {
		path {
			fill: darken(#FBBA00, 10);
		}
	}
}

#south-west {
	path, polygon {
		fill: #007B3D;
	}
	&:hover {
		path, polygon {
			fill: darken(#007B3D, 10);
		}
	}
}

#south-east {
	path, polygon {
		fill: #851F82;
	}
	&:hover {
		path, polygon {
			fill: darken(#851F82, 10);
		}
	}
}

#london-and-east-england {
	path, polygon {
		fill: #E50051;
	}
	&:hover {
		path, polygon {
			fill: darken(#E50051, 10);
		}
	}
}

#midlands {
	path, polygon {
		fill: #5BC5F2;
	}
	&:hover {
		path, polygon {
			fill: darken(#5BC5F2, 10);
		}
	}
}

#north-west {
	path, polygon {
		fill: #952456;
	}
	&:hover {
		path, polygon {
			fill: darken(#952456, 10);
		}
	}
}

#north {
	path, polygon {
		fill: #A78870;
	}
	&:hover {
		path, polygon {
			fill: darken(#A78870, 10);
		}
	}
}

#scotland {
	path, polygon {
		fill: #0069B4;
	}
	&:hover {
		path, polygon {
			fill: darken(#0069B4, 10);
		}
	}
}

#clickableMap g .placeholder {
	font-size: 6.4px;
	font-family: $font-family-base;
	display: none;
	polygon {
		fill: $_purple;
	}
	text {
		fill: #fff;
	}
}

#clickableMap g:hover {
	cursor: pointer;
	.placeholder {
		display: block;
		text-decoration: none;
	}
	a:hover {
		text-decoration: none;
	}
}

// DES-5464
.donate.subsite .post {
  .menuSub,
  .headerTextSubsite,
  .headerWrapper {
    order: 0;
  }
}

// IM-923 xmas appeal

@include appeal-field("winter-appeal-2021", "christmas");

.appeal-winter-appeal-2021 {
  .post {
    .headerText .title {
      max-width: $container-max-width;
    }
    .appealActionsWrapper {
      max-width: $container-max-width;
      padding-left: $spacer;
      padding-right: $spacer;
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {

  .postAside a#lbAddToCalendar {
    display: none;
  }

}